
<template>
  <div>
    <div class="container-fluid px-4">
      <div class="row">
        <tab-group
          v-if="tabsLists.includes($route.name) === true"
          :routes="routes"
        ></tab-group>
        <div class="col-12 pl-0">
          <router-view/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import TabGroup from "@/components/common/TabGroup.vue";

export default {
  name: "CompanyAudit",
  data() {
    return {
      tabsLists : [
        'CompanyList',
        'CompanyAuditDue',
        'CompanyObjectiveEvidenceList',
      ],
      routes : [
        {
          name : 'CompanyList',
          text : 'Company Audit Records'
        },
        {
          name : 'CompanyObjectiveEvidenceList',
          text : 'Company Objective Evidence'
        },
        {
          name : 'CompanyAuditDue',
          text : 'Company Audit Due'
        },
      ]
    }
  },
  components: {TabGroup},
  computed : {
    parentRoute() {
      const routePath = this.$route.path;
      let parent = null;

      this.$router.options.routes.forEach(route => {
        if (route.children) {
          route.children.forEach(child => {
            if (child.path === routePath || `/${child.path}` === routePath) {
              parent = route;
            }
          });
        }
      });

      return parent;
    }
  }
}
</script>



